nav {
    position: sticky;
    top: 0;
    height: 60px;
    width: 210px;
    font-size: 13px;
    text-align: center;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-color);
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    height: 100%;
}

nav ul li {
    position: relative;
    width: auto;
    height: 100%;
}

nav ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 10px;
    color: var(--white-color);
    text-decoration: none;
    outline: none;
    position: relative;
    height: 40px;
    margin-top: 10px;
}

.nav-monitor-feed-page .nav-icon {
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 32 32%22 width=%2240px%22 height=%2240px%22 fill=%22none%22 stroke=%22white%22 stroke-linejoin=%22round%22 stroke-miterlimit=%2210%22 stroke-width=%222%22%3E%3Cg%3E%3Cpath d=%22M25,3V1H3v28 c0,1.105,0.895,2,2,2h22c1.105,0,2-0.895,2-2V3H25z%22 /%3E%3Crect height=%226%22 width=%2214%22 x=%227%22 y=%225%22 /%3E%3Cline x1=%2225%22 x2=%2225%22 y1=%223%22 y2=%2224%22 /%3E%3Cline x1=%2225%22 x2=%2225%22 y1=%2226%22 y2=%2228%22 /%3E%3Cline x1=%226%22 x2=%2222%22 y1=%2215%22 y2=%2215%22 /%3E%3Cline x1=%226%22 x2=%2222%22 y1=%2219%22 y2=%2219%22 /%3E%3Cline x1=%226%22 x2=%2218%22 y1=%2223%22 y2=%2223%22 /%3E%3C/g%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-monitor-feed-page .nav-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 10%;

    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 6px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-monitor-feed-page:hover .nav-item {
    opacity: 1;
    visibility: visible;
}

.nav-token-create .nav-icon {
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" width="30" height="30"> <title/> <g> <path d="M66,42H54V30a6,6,0,0,0-12,0V42H30a6,6,0,0,0,0,12H42V66a6,6,0,0,0,12,0V54H66a6,6,0,0,0,0-12Z" fill="white"/> <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" fill="white"/> </g> </svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -20px;
}

.nav-token-create .nav-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 6px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-token-create:hover .nav-item {
    opacity: 1;
    visibility: visible;
}

.nav-settings .nav-icon {
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cg%3E%3Cpath d="M500.633 211.454l-58.729-14.443c-3.53-11.133-8.071-21.929-13.55-32.256 8.818-14.678 27.349-45.571 27.349-45.571 3.545-5.903 2.607-13.462-2.256-18.325l-42.422-42.422c-4.863-4.878-12.407-5.815-18.325-2.256L347.055 83.53c-10.269-5.435-21.006-9.932-32.065-13.433l-14.443-58.729C298.876 4.688 292.885 0 286 0h-60c-6.885 0-12.891 4.688-14.546 11.367 0 0-10.005 40.99-14.429 58.715-11.792 3.735-23.188 8.584-34.043 14.502l-47.329-28.403c-5.918-3.516-13.447-2.607-18.325 2.256l-42.422 42.422c-4.863 4.863-5.801 12.422-2.256 18.325l29.268 48.882c-4.717 9.302-8.672 18.984-11.821 28.901l-58.729 14.487C4.688 213.124 0 219.115 0 226v60c0 6.885 4.688 12.891 11.367 14.546l58.744 14.443c3.56 11.294 8.188 22.266 13.799 32.798l-26.191 43.652c-3.545 5.903-2.607 13.462 2.256 18.325l42.422 42.422c4.849 4.849 12.407 5.771 18.325 2.256 0 0 29.37-17.607 43.755-26.221 10.415 5.552 21.313 10.137 32.549 13.696l14.429 58.715C213.109 507.313 219.115 512 226 512h60c6.885 0 12.876-4.688 14.546-11.367l14.429-58.715c11.558-3.662 22.69-8.394 33.281-14.136 14.78 8.862 44.443 26.66 44.443 26.66 5.903 3.53 13.462 2.622 18.325-2.256l42.422-42.422c4.863-4.863 5.801-12.422 2.256-18.325l-26.968-44.927c5.317-10.093 9.727-20.654 13.169-31.523l58.729-14.443C507.313 298.876 512 292.885 512 286v-60c0-6.885-4.688-12.891-11.367-14.546zM256 361c-57.891 0-105-47.109-105-105s47.109-105 105-105 105 47.109 105 105S313.891 361 256 361z" fill="%23FFFFFF"/%3E%3C/g%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -20px;
}

.nav-settings .nav-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 6px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-settings:hover .nav-item {
    opacity: 1;
    visibility: visible;
}