.send-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.login-tg {
    margin-top: 20px;
}

.sign-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
}

.send-input,
.sign-input {
    width: 150px;
    height: 35px;
    border-radius: 8px;
}

.button-accent {
    width: 100px;
    height: 30px;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    font-weight: 700;

}

.button-accent:hover {
    background-color: #b0b0b0;
}

p {
    color: white;
}

.tg-row {
    align-items: center;
    display: flex;
    white-space: nowrap;
    gap: 10px;
    justify-content: center;

}

.registration-container {
    width: 500px;
    height: 500px;
    line-height: 2.5;
}

.channel-reg {
    align-items: center;
    display: flex;
    justify-content: center;
}

.tg-row input:not([type="checkbox"]) {
    border-radius: 8px;
    width: 170px;
    height: 35px;
}

.title-input {
    margin-right: 70px;
}

.about-input {
    margin-right: 85px;
}

.admin-input {
    margin-right: 85px;
}

.tg-button-container {
    justify-content: center;
    display: flex;
}