*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    overflow-y: scroll;
}

html,
body,
p,
input[type='checkbox'],
input[type='radio'],
h3 {
    margin: 0;
    padding: 0;
    border: 0;
}

:root {
    --peach-background-gradient: linear-gradient(to bottom, #f1cfa2 0%, #ffffff 100%);
    --green-background-gradient: linear-gradient(to bottom, #7df3a0 0%, #ffffff 100%);
    --blue-background-gradient: linear-gradient(to bottom, #b3d9ff 0%, #ffffff 100%);
    --orange-background-gradient: linear-gradient(to bottom, #f9a88b 0%, #ffffff 100%);
    --purple-background-gradient: linear-gradient(to bottom, #a29ce1 0%, #ffffff 100%);
    --pink-background-gradient: linear-gradient(to bottom, #f798c6 0%, #ffffff 100%);
    --dark-blue-background-gradient: linear-gradient(to bottom, #5462ff -20%, #ffffff 100%);
    --pastel-background-gradient: linear-gradient(to bottom, #f7b764 0%, #ffffff 100%);
    --grey-background-gradient: linear-gradient(to bottom, #8a8a8a 0%, #ffffff 100%);
    --light-green-gradient: linear-gradient(to bottom, #40c568 0%, #ffffff 100%);
    --yellow-background-gradient: linear-gradient(to bottom, #fbf661 0%, #ffffff 100%);
    --turquoise-background-gradient: linear-gradient(to bottom, #54fdd3 0%, #ffffff 100%);
    --browney-background-gradient: linear-gradient(to bottom, #f8d03e 0%, #ffffff 100%);


    --main-background-gradient: radial-gradient(circle at top left, #021f25 50%, #000000 70%);

    --nav-background-color: rgba(255, 255, 255, 0.1);
}

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 19px;

    color: var(--white-color);
    background: var(--main-background-gradient);
    background-attachment: fixed;
    zoom: 80%;
}

.header {
    height: 60px;
    min-width: 11400px;
    background-color: var(--nav-background-color);
    display: flex;
    align-items: center;
}

.inputs {
    display: flex;
    gap: 10px;
}

.token-create-header,
.settings-page-header {
    min-width: auto;
}