.body-container {
    margin-top: 40px;
    margin-left: 40px;
}

.body-container h3 {
    color: white;
}

.key-set-remove {
    display: flex;
    gap: 15px;
    white-space: nowrap;
    align-items: center;
}

.pk-deployer {
    width: 400px;
    height: 40px;
    border-radius: 10px;
}

.flash-icon {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="255.17" fill="%23474747" r="250"/><path clip-rule="evenodd" d="m296.355 73.014-147.641 194.64c-4.206 5.544-.251 13.507 6.708 13.507h89.194c5.758 0 9.817 5.651 7.978 11.108l-46.264 137.277c-3 8.901 8.622 15.286 14.526 7.981l142.269-176.008c4.45-5.506.532-13.712-6.548-13.712h-84.71c-5.648 0-9.694-5.451-8.059-10.857l47.314-156.41c2.719-8.988-9.092-15.008-14.767-7.526z" fill="%23fff" fill-rule="evenodd"/></svg>');
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.arrow-icon {
    background-image: url('data:image/svg+xml;utf8,<svg fill="%230080FF" width="50px" height="50px" viewBox="0 0 493.456 493.456" xmlns="http://www.w3.org/2000/svg"><circle cx="246.728" cy="246.728" r="246.728" fill="%23387ACD"/><path fill="%23FFFFFF" d="M321.138,250.492L210.822,360.804c-2.004,2.008-5.548,2.008-7.556,0l-8.98-8.968c-2.076-2.084-2.072-5.48,0.004-7.564l94.952-94.94c0.712-0.72,1.116-1.7,1.116-2.732c0-1.016-0.408-2.004-1.116-2.72l-94.78-94.776c-1-1-1.56-2.344-1.56-3.792c0-1.42,0.556-2.772,1.556-3.764l8.968-8.976c1.004-1,2.352-1.556,3.78-1.556c1.436-0.008,2.772,0.556,3.776,1.556l110.156,110.152c1.02,1.016,1.572,2.372,1.552,3.936C322.71,248.116,322.158,249.476,321.138,250.492z"/></svg>');
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.flash,
.arrow,
.copy {
    width: 70px;
    height: 40px;
    border-radius: 10px;
}

.sol-buy-init {
    display: flex;
    align-items: center;
    gap: 40px;
    white-space: nowrap;
    margin-top: 30px;
}

.flash-inp,
.arrow-inp,
.copy-inp {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 20px;
}

.buy-sell-prio {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 30px;
    margin-top: 30px;
}

.buy-sell {
    width: 100px;
    height: 40px;
    border-radius: 10px;
}

.buy-sell-jito {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 40px;
    margin-top: 20px;
}

.sell-jito {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: -10px;
    gap: 38px;
}

.set-remove {
    background-color: #00ff77;
    color: #000;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    padding: 10px 10px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.set-remove:hover {
    background-color: #02c25c;
}

.copy-icon {
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" width="30" height="30"> <title/> <g> <path d="M66,42H54V30a6,6,0,0,0-12,0V42H30a6,6,0,0,0,0,12H42V66a6,6,0,0,0,12,0V54H66a6,6,0,0,0,0-12Z" fill="white"/> <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" fill="white"/> </g> </svg>');
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.h3-defaults {
    margin-top: 40px;
}

.defaults {
    margin-top: 40px;

}

.flash-defaults,
.arrow-defaults,
.copy-defaults {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 30px;
}

.arrow-defaults,
.copy-defaults {
    margin-top: 20px;
}

.icon-inp,
.inp-text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 15px;
}