.card-settings-container {
    white-space: nowrap;
    display: flex;
    gap: 50px;
}

.sett-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
    gap: 20px;
    color: white;
    margin-left: 300px;
}

.naming,
.symbols,
.description-case,
.img-case {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    gap: 10px;
}

.naming {
    margin-left: -40px;
}

.symbols {
    margin-left: 25px;
}

.symbol-input {
    width: 357px;
    height: 40px;
    border-radius: 8px;
    padding: 0 10px;
}

.naming input {
    width: 500px;
    height: 40px;
    border-radius: 8px;
    padding: 0 10px;
}

.description-case textarea {
    height: 100px;
    width: 500px;
    margin-left: 10px;
    border-radius: 8px;
    padding: 0 10px;
    font-size: 16px;
    box-sizing: border-box;
    resize: vertical;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.img-case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.img-case .img {
    width: 250px;
    height: 250px;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
}

.img-case .img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.img-btn {
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 60px;
    margin-left: -80px;
}

.btn-case {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-right: 40px;
}

.btn-case input[type='file'] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    width: 70px;
    height: 70px;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' ?><svg height='70px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='70px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path d='M398.1,233.2c0-1.2,0.2-2.4,0.2-3.6c0-65-51.8-117.6-115.7-117.6c-46.1,0-85.7,27.4-104.3,67c-8.1-4.1-17.2-6.5-26.8-6.5 c-29.5,0-54.1,21.9-58.8,50.5C57.3,235.2,32,269.1,32,309c0,50.2,40.1,91,89.5,91H224v-80l-48.2,0l80.2-83.7l80.2,83.6l-48.2,0v80 h110.3c45.2,0,81.7-37.5,81.7-83.4C480,270.6,443.3,233.3,398.1,233.2z' fill='%23FFFFFF'/></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    cursor: pointer;
}

.delete-btn {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' ?><svg fill='%23FFFFFF' height='70' viewBox='-2 -2 24 24' width='70' xmlns='http://www.w3.org/2000/svg'><path d='m11.414 10 2.829-2.828a1 1 0 1 0-1.415-1.415L10 8.586 7.172 5.757a1 1 0 0 0-1.415 1.415L8.586 10l-2.829 2.828a1 1 0 0 0 1.415 1.415L10 11.414l2.828 2.829a1 1 0 0 0 1.415-1.415L11.414 10zM4 0h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'/></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.delete-btn div {
    display: none;
}

.instant-case button {
    width: 200px;
    padding: 10px 5px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Orbitron', sans-serif;
    font-size: 24px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
    background: linear-gradient(to bottom, #007bff, #0056b3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    font-family: 'Russo One', sans-serif;
}

button.instant-pump {
    background: linear-gradient(to bottom, #007bff, #0056b3);
    color: white;
}

button.instant-pump:hover {
    background: #3e60f8;
}

.auto-deploy {
    display: flex;
    white-space: nowrap;
    gap: 5px;
    align-items: center;
}

input[type='checkbox'] {
    height: 25px;
    width: 25px;
    border-radius: 25%;
    border: 2px solid #ccc;
    appearance: none;
    outline: none;
    cursor: pointer;
    background-color: #fff;
}

input[type='checkbox']:checked {
    background-color: hsl(0, 0%, 100%);
    border: 2px solid #ffffff;
    position: relative;
}

input[type='checkbox']:checked::before {
    content: '✓';
    color: rgb(0, 0, 0);
    font-weight: bold;
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 18px;
}

.card p {
    color: white;
    margin-top: 50px;
    margin-left: 20px;
}

input,
textarea {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
}

.send-case button:disabled {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    opacity: 0.6;
}

.tweetForm {
    margin-left: 10px;
}

.set-btn {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23ffffff%22%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M3%2C9A1%2C1%2C0%2C0%2C0%2C4%2C8V5A1%2C1%2C0%2C0%2C1%2C5%2C4H8A1%2C1%2C0%2C0%2C0%2C8%2C2H5A3%2C3%2C0%2C0%2C0%2C2%2C5V8A1%2C1%2C0%2C0%2C0%2C3%2C9ZM8%2C20H5a1%2C1%2C0%2C0%2C1-1-1V16a1%2C1%2C0%2C0%2C0-2%2C0v3a3%2C3%2C0%2C0%2C0%2C3%2C3H8a1%2C1%2C0%2C0%2C0%2C0-2ZM12%2C8a4%2C4%2C0%2C1%2C0%2C4%2C4A4%2C4%2C0%2C0%2C0%2C12%2C8Zm0%2C6a2%2C2%2C0%2C1%2C1%2C2-2A2%2C2%2C0%2C0%2C1%2C12%2C14ZM19%2C2H16a1%2C1%2C0%2C0%2C0%2C0%2C2h3a1%2C1%2C0%2C0%2C1%2C1%2C1V8a1%2C1%2C0%2C0%2C0%2C2%2C0V5A3%2C3%2C0%2C0%2C0%2C19%2C2Zm2%2C13a1%2C1%2C0%2C0%2C0-1%2C1v3a1%2C1%2C0%2C0%2C1-1%2C1H16a1%2C1%2C0%2C0%2C0%2C0%2C2h3a3%2C3%2C0%2C0%2C0%2C3-3V16A1%2C1%2C0%2C0%2C0%2C21%2C15Z%22/%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;

    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
}

.download-btn {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22548.176px%22%20height%3D%22548.176px%22%20viewBox%3D%220%200%20548.176%20548.176%22%20style%3D%22enable-background%3Anew%200%200%20548.176%20548.176%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20transform%3D%22scale(-1%2C%201)%20translate(-548.176%2C%200)%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M524.326%2C297.352c-15.896-19.89-36.21-32.782-60.959-38.684c7.81-11.8%2C11.704-24.934%2C11.704-39.399%0A0-20.177-7.139-37.401-21.409-51.678c-14.273-14.272-31.498-21.411-51.675-21.411c-18.083%2C0-33.879%2C5.901-47.39%2C17.703%0A-11.225-27.41-29.171-49.393-53.817-65.95c-24.646-16.562-51.818-24.842-81.514-24.842c-40.349%2C0-74.802%2C14.279-103.353%2C42.83%0A-28.553%2C28.544-42.825%2C62.999-42.825%2C103.351c0%2C2.474%2C0.191%2C6.567%2C0.571%2C12.275c-22.459%2C10.469-40.349%2C26.171-53.676%2C47.106%0AC6.661%2C299.594%2C0%2C322.43%2C0%2C347.179c0%2C35.214%2C12.517%2C65.329%2C37.544%2C90.358c25.028%2C25.037%2C55.15%2C37.548%2C90.362%2C37.548h310.636%0Ac30.259%2C0%2C56.096-10.711%2C77.512-32.12c21.413-21.409%2C32.121-47.246%2C32.121-77.516C548.172%2C339.944%2C540.223%2C317.248%2C524.326%2C297.352%0Az%20M362.595%2C308.344L262.38%2C408.565c-1.711%2C1.707-3.901%2C2.566-6.567%2C2.566c-2.664%2C0-4.854-0.859-6.567-2.566L148.75%2C308.063%0Ac-1.713-1.711-2.568-3.901-2.568-6.567c0-2.474%2C0.9-4.616%2C2.708-6.423c1.812-1.808%2C3.949-2.711%2C6.423-2.711h63.954V191.865%0Ac0-2.474%2C0.905-4.616%2C2.712-6.427c1.809-1.805%2C3.949-2.708%2C6.423-2.708h54.823c2.478%2C0%2C4.609%2C0.9%2C6.427%2C2.708%0Ac1.804%2C1.811%2C2.707%2C3.953%2C2.707%2C6.427v100.497h63.954c2.665%2C0%2C4.855%2C0.855%2C6.563%2C2.566c1.714%2C1.711%2C2.562%2C3.901%2C2.562%2C6.567%0AC365.438%2C303.789%2C364.494%2C306.064%2C362.595%2C308.344z%22/%3E%3C/g%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;

    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
}

.download-btn-case {
    margin-top: 5px;
}

.delete-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 40%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.delete-btn::after {
    content: '';
    position: absolute;
    top: -25px;

    opacity: 0;
    visibility: hidden;
}

.upload-icon:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.upload-icon::after {
    content: '';
    position: absolute;
    top: -25px;

    opacity: 0;
    visibility: hidden;
}

.set-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100px;
    left: 40%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.set-btn::after {
    content: '';
    position: absolute;
    top: 100px;

    opacity: 0;
    visibility: hidden;
}

.download-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 170px;
    left: 40%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.download-btn::after {
    content: '';
    position: absolute;
    top: 170px;

    opacity: 0;
    visibility: hidden;
}

.ai-btn {
    background-color: rgb(195, 166, 199);
    color: #000;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 4px #a08ea9;
    transition: transform 0.1s ease;
    font-family: 'Russo One', sans-serif;
}

.ai-btn:hover {
    background-color: rgb(184, 147, 189);
}

.ai-btn:active {
    transform: translateY(2px);
    box-shadow: 0 2px #a08ea9;
}

.extract-text-btn,
.generate-ai-btn {
    display: flex;
    justify-content: center;
    width: 100%;
}

.form1 {
    zoom: 60%;
}

.header-card {
    justify-content: center;
    display: flex;
}

.send-instant-case h3,
.send-instant-case p {
    color: white;
}

.instant-pump-case {
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    width: 520px;
}

.wallet-balance-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.instant-case {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 30px;
}

.sol-initial-buy-case input {
    height: 40px;
    width: 60px;
    border-radius: 10px;
}

.sol-initial-buy-case {
    display: flex;
    align-items: center;
    gap: 15px;
}

.generate-extract-btn-case {
    display: flex;
    align-items: center;
    gap: 40px;
}

.sett-naming-container {
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 800px;
    gap: 20px;
    color: white;
}

.create-token-container {
    width: 650px;
    height: auto;
    margin-top: 40px;
}

.picture-buttons-case {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    width: 100%;
    max-width: 800px;
    gap: 20px;
    color: white;
    margin-top: 30px;
}

.pic-btn {
    background-color: #32ff7e;
    color: #000;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    font-family: 'Russo One', sans-serif;
    border: none;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 4px #1b8a4d;
    transition: transform 0.1s ease;
}

.pic-btn:hover {
    background-color: #28e070;
}

.pic-btn:active {
    transform: translateY(2px);
    box-shadow: 0 2px #1b8a4d;
}

.link-case {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.links-container {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 800px;
    gap: 20px;
    color: white;
}

.links-container h3 {
    color: white;
}

.links {
    width: 500px;
    height: 40px;
    border-radius: 8px;
    padding: 0 10px;
}

.send-case {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
}

.send-case button {
    width: auto;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Russo One', sans-serif;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
}

button.spl:hover {
    background-color: #7e1d87;
    color: white;
}

button.spl {
    background: #5a0080;
    color: white;
}

button.pump {
    background: #173ce0;
    color: white;
}

button.pump:hover {
    background: #3e60f8;
}

button.erc {
    background: #03b7bd;
    color: white;
}

button.erc:hover {
    background: #019499;
    color: white;
}

.auto-deploy {
    display: flex;
    white-space: nowrap;
    gap: 5px;
}

input[type='checkbox'] {
    height: 25px;
    width: 25px;
    border-radius: 25%;
    border: 2px solid #ccc;
    appearance: none;
    outline: none;
    cursor: pointer;
    background-color: #fff;
}

input[type='checkbox']:checked {
    background-color: hsl(0, 0%, 100%);
    border: 2px solid #ffffff;
    position: relative;
}

input[type='checkbox']:checked::before {
    content: '✓';
    color: rgb(0, 0, 0);
    font-weight: bold;
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 18px;
}

.send-case button:disabled {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    opacity: 0.6;
}

.session {
    display: flex;
    align-items: center;
    gap: 5px;
}

.send-spl {
    display: flex;
    align-items: center;
    gap: 40px;
}

.send-erc {
    display: flex;
    align-items: center;
    gap: 40px;
}

.send-pump {
    display: flex;
    align-items: center;
    gap: 40px;
}

.deploy-session {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

.tg-create-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
}

.tg-create-container h3 {
    color: white;
}

.tg {
    margin-top: 20px;
}

.import-menu-btn {
    width: 45px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg id='bold' enable-background='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m13.735 21.795-4.5-4.25c-.15-.141-.235-.338-.235-.545s.085-.404.235-.545l4.5-4.25c.476-.448 1.265-.114 1.265.545v2.25h5.25c1.24 0 2.25-1.009 2.25-2.25 0-.414.336-.75.75-.75s.75.336.75.75v1.5c0 2.619-2.131 4.75-4.75 4.75h-4.25v2.25c0 .662-.792.992-1.265.545z' fill='%23ffffff'/%3E%3Cpath d='m12 0c-6.617 0-12 5.383-12 12s5.383 12 12 12c.518 0 1.026-.044 1.527-.108-.394-.108-.856-.35-1.166-.643-4.732-4.47-5.087-4.564-5.312-5.77-.218-1.221.288-1.983 1.119-2.767-.067-.549-.109-1.125-.136-1.712h1.948s2.51-2.437 3.02-2.697v-2.329c.88-.035 1.742-.115 2.575-.24.181.884.314 1.842.377 2.862.804.636 1.048 1.426 1.048 2.404h.976c.013-.33.024-.661.024-1 0-1.672-.156-3.242-.439-4.663.982-.246 1.899-.553 2.715-.923.793 1.174 1.346 2.523 1.583 3.977.409-.242.881-.391 1.391-.391.199 0 .392.024.579.064-.93-5.697-5.872-10.064-11.829-10.064zm-9.949 11c.168-1.688.758-3.255 1.662-4.591.819.373 1.739.681 2.726.928-.225 1.133-.366 2.363-.415 3.663zm3.017-6.191c.865-.834 1.875-1.517 2.994-1.998-.438.744-.817 1.616-1.127 2.593-.666-.166-1.291-.365-1.867-.595zm5.932-2.424v3.59c-.705-.03-1.392-.095-2.059-.186.552-1.633 1.289-2.83 2.059-3.404zm-2.943 18.795c-1.116-.481-2.125-1.158-2.988-1.99.577-.229 1.201-.428 1.866-.594.309.975.686 1.843 1.122 2.584zm-1.618-4.516c-.983.246-1.901.553-2.717.923-.905-1.337-1.502-2.898-1.67-4.587h3.972c.049 1.3.19 2.531.415 3.664zm1.584-5.664c.051-1.171.193-2.266.398-3.266.834.124 1.698.205 2.579.24v3.026zm4.977-5.025v-3.594c.769.574 1.506 1.775 2.057 3.408-.667.091-1.353.156-2.057.186zm2.945-3.151c1.113.482 2.12 1.157 2.98 1.989-.575.229-1.197.427-1.86.592-.308-.973-.685-1.84-1.12-2.581z' fill='%23ffffff'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
    margin-left: 70px;
    align-items: center;
}

.image-manipulation-btns {
    display: flex;
    justify-content: space-between;
}

.image-editor-btn {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' height='512' viewBox='0 0 48 48' width='512' xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' fill='%23ffffff'><path d='m42 29.62v-1.56l-.58 1z'/><path d='m37 4h-28a5 5 0 0 0 -5 5v20.69l5.11-4.94a4.968 4.968 0 0 1 6.95 0l2.38 2.29a.978.978 0 0 0 1.38 0l6.28-6.07a5 5 0 0 1 6.96 0l.96.92 2.78-4.82a4.01 4.01 0 0 1 3.47-2 3.852 3.852 0 0 1 1.73.4v-6.47a5 5 0 0 0 -5-5zm-23.5 14a4.5 4.5 0 1 1 4.5-4.5 4.494 4.494 0 0 1 -4.5 4.5z'/><path d='m31.67 22.41 1.31 1.27-7.62 13.21a6.062 6.062 0 0 0 -.81 3.1l.04 2.01h-15.59a5 5 0 0 1 -5-5v-4.53l6.5-6.28a2.988 2.988 0 0 1 4.17 0l2.37 2.29a2.988 2.988 0 0 0 4.17 0l6.29-6.07a2.988 2.988 0 0 1 4.17 0z'/><path d='m42 32.4v4.6a5 5 0 0 1 -5 5h-3.05a.413.413 0 0 0 .07-.11l6.37-11.04z'/><path d='m36.7 21.249-9.163 15.87 5.2 3 9.163-15.87zm.287 7.5a1 1 0 0 1 -1.732-1l1.5-2.6a1 1 0 1 1 1.732 1z'/><path d='m42.9 22.516.837-1.449a2 2 0 0 0 -.737-2.732l-1.732-1a2 2 0 0 0 -2.732.732l-.837 1.45z'/><path d='m26.674 38.932a3.983 3.983 0 0 0 -.12 1.028l.052 3.055a1 1 0 0 0 1.485.858l2.672-1.483a4.007 4.007 0 0 0 .831-.618z'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
    align-items: center;
}

.image-editor-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.image-editor-btn::after {
    content: '';
    position: absolute;
    top: -25px;

    opacity: 0;
    visibility: hidden;
}

.import-menu-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 45px;
    left: 100%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.import-menu-btn::after {
    content: '';
    position: absolute;
    top: 45px;

    opacity: 0;
    visibility: hidden;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    display: flex;
    flex-direction: column;
    background: #1c1c1e;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 700px;
    gap: 20px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-header h3 {
    margin: 0;
    font-size: 18px;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.close-btn:hover {
    color: red;
}

.popup-input {
    width: 80%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background: #2c2c2e;
    color: white;
    outline: none;
    font-size: 16px;
}

.import-btn {
    width: 15%;
    height: 40px;
    background-color: #32ff7e;
    color: #000;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 4px #1b8a4d;
    transition: transform 0.1s ease;
}

.import-btn:hover {
    background-color: #28e070;
}

.import-btn:active {
    transform: translateY(2px);
    box-shadow: 0 2px #1b8a4d;
}

.import-inp-btn {
    display: flex;
    align-items: center;
    gap: 5%;
}

.no-post-side-panel h3 {
    color: white;
    font-size: 16px;
}

.no-post-side-panel input {
    height: 40px;
    border-radius: 10px;
    width: 200px;
}

.no-post-side-panel button {
    height: 40px;
}

.no-post-side-panel {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 40px;
    justify-content: flex-start;
}

.side-panel-case {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5px;
}

.side-panel-inp-btn-case {
    display: flex;
    align-items: center;
    gap: 15px;
}

.copy-btn {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-21 0 512 512" height="512pt" width="512pt"%3E%3Cpath d="m186.667969 416c-49.984375 0-90.667969-40.683594-90.667969-90.667969v-218.664062h-37.332031c-32.363281 0-58.667969 26.300781-58.667969 58.664062v288c0 32.363281 26.304688 58.667969 58.667969 58.667969h266.664062c32.363281 0 58.667969-26.304688 58.667969-58.667969v-37.332031zm0 0" fill="%23FFFFFF"/%3E%3Cpath d="m469.332031 58.667969c0-32.40625-26.261719-58.667969-58.664062-58.667969h-224c-32.40625 0-58.667969 26.261719-58.667969 58.667969v266.664062c0 32.40625 26.261719 58.667969 58.667969 58.667969h224c32.402343 0 58.664062-26.261719 58.664062-58.667969zm0 0" fill="%23FFFFFF"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#pixie-container nav {
    width: 100%;
}

.radio-custom-pump p {
    color: white;
}

.radio-custom-pump {
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
}

.radio-option {
    font-size: 22px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.radio-option input[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}

.radio-option input[type='radio']:checked {
    border: 3px solid white;
    background-color: black;
}

.custom-wallet-address {
    height: 40px;
    border-radius: 10px;
}

.ocr-text h3 {
    color: white;
}

.ocr-text {
    justify-content: flex-start;
    align-items: center;
}

.ocr-case {
    margin-top: 10px;
    margin-left: 10px;
}

.ocr-main-text {
    font-size: 14px;
    margin-top: 5px;
}
