.container-form {
    width: 325px;
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.feed-source {
    display: flex;
    align-items: center;
    width: 340px;
    height: 60px;
    padding: 5px;
    color: white;
    justify-content: center;
    margin-left: -5px;

}

.feed-source.realDonaldTrump {
    transform: scale(0.89);
}

.feed-source.elonmusk-legacy h3 {
    font-size: 16px;

}

.icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.settings-icon {
    display: inline-block;
    width: 20px;
    height: 30px;
    background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><circle cx="16" cy="5" r="3" fill="white"/><circle cx="16" cy="16" r="3" fill="white"/><circle cx="16" cy="27" r="3" fill="white"/></svg>');
    background-size: cover;
    background-repeat: no-repeat;
}

.link-to-channel {
    color: white;

}

.link-to-channel h3 {
    font-size: 14px;

}

.name-url p {
    font-size: 20px;
}

.name-url {
    display: flex;
    flex-direction: column;
    height: 60px;
    padding: 5px;
    justify-content: space-between;
}

.icon-naming-link {
    display: flex;
    align-items: center;
    padding: 15px;
    color: white;
    gap: 10px;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}

.volume-check {
    display: inline-block;
    cursor: pointer;
    width: 55px;
    height: 55px;
}

.checkbox-icon {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s ease-in-out;
}

.volume-check.checked .checkbox-icon {
    background-image: url('data:image/svg+xml,%3Csvg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M264,416.19a23.92,23.92,0,0,1-14.21-4.69l-.66-.51-91.46-75H88a24,24,0,0,1-24-24V200a24,24,0,0,1,24-24h69.65l91.46-75,.66-.51A24,24,0,0,1,288,119.83V392.17a24,24,0,0,1-24,24Z" fill="%23ffffff"/%3E%3Cpath d="M352,336a16,16,0,0,1-14.29-23.18c9.49-18.9,14.3-38,14.3-56.82,0-19.36-4.66-37.92-14.25-56.73a16,16,0,0,1,28.5-14.54C378.2,208.16,384,231.47,384,256c0,23.83-6,47.78-17.7,71.18A16,16,0,0,1,352,336Z" fill="%23ffffff"/%3E%3Cpath d="M400,384a16,16,0,0,1-13.87-24C405,327.05,416,299.45,416,256c0-44.12-10.94-71.52-29.83-103.95A16,16,0,0,1,413.83,136C434.92,172.16,448,204.88,448,256c0,50.36-13.06,83.24-34.12,120A16,16,0,0,1,400,384Z" fill="%23ffffff"/%3E%3C/svg%3E');
}

.volume-check:not(.checked) .checkbox-icon {
    background-image: url('data:image/svg+xml,%3Csvg width="32" height="32" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"%3E%3Cline style="fill:none;stroke:%23ffffff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px" x1="416" x2="64" y1="432" y2="80"/%3E%3Cpath d="M243.33,98.86a23.89,23.89,0,0,0-25.55,1.82l-.66.51L188.6,124.54a8,8,0,0,0-.59,11.85l54.33,54.33A8,8,0,0,0,256,185.06V120.57A24.51,24.51,0,0,0,243.33,98.86Z" fill="%23ffffff"/%3E%3Cpath d="M251.33,335.29,96.69,180.69A16,16,0,0,0,85.38,176H56a24,24,0,0,0-24,24V312a24,24,0,0,0,24,24h69.76l92,75.31A23.9,23.9,0,0,0,243.63,413,24.51,24.51,0,0,0,256,391.45V346.59A16,16,0,0,0,251.33,335.29Z" fill="%23ffffff"/%3E%3Cpath d="M352,256c0-24.56-5.81-47.87-17.75-71.27a16,16,0,1,0-28.5,14.55C315.34,218.06,320,236.62,320,256q0,4-.31,8.13a8,8,0,0,0,2.32,6.25l14.36,14.36a8,8,0,0,0,13.55-4.31A146,146,0,0,0,352,256Z" fill="%23ffffff"/%3E%3Cpath d="M416,256c0-51.18-13.08-83.89-34.18-120.06a16,16,0,0,0-27.64,16.12C373.07,184.44,384,211.83,384,256c0,23.83-3.29,42.88-9.37,60.65a8,8,0,0,0,1.9,8.26L389,337.4a8,8,0,0,0,13.13-2.79C411,311.76,416,287.26,416,256Z" fill="%23ffffff"/%3E%3C/svg%3E');
}

.volume-check:active .checkbox-icon {
    transform: scale(0.95);
}

.more-options {
    position: relative;
}

.settings-container {
    position: relative;
    display: inline-block;
    margin-left: 20px;

}

.settings-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
}

.settings-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: black;
    color: white;
    border: 1px solid #333;
    padding: 10px;
    z-index: 1000;
    width: 300px;
    display: flex;
    flex-direction: column;

}

.menu-section {
    margin-right: 20px;
    flex: 1;
}

.menu-section h4 {
    margin: 0;
    font-size: 1rem;
    margin-bottom: 5px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

input[type="checkbox"] {
    margin-right: 5px;
}

.twitter-menu {
    display: flex;
    margin-left: 10%;
}

.text-h4 {
    display: flex;
    justify-content: center;
}

.choose-sound {
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}

.radio-buttons {
    display: flex;
    gap: 15%;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

.radio-label input[type="radio"] {
    display: none;
}

.custom-radio {
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.radio-label input[type="radio"]:checked+.custom-radio::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.import-sound-btn {
    display: none;
}

.custom-file-upload {
    height: 35px;
    width: 35px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff' stroke='%23ffffff'%3E%3Cpath d='M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpolyline points='7.9 6.7 12 2.7 16.1 6.7' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline x1='12' x2='12' y1='16.3' y2='4.8' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
}